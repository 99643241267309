import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"id":"data-list"}},[_c(VCardText,{staticClass:"d-flex align-flex-start flex-wrap"},[_c('h1',[_vm._v("Ustoz guruhlari va talabalarini import qilish")])]),_c('br'),_c('br'),_c('h2',{staticClass:"ml-5 mb-5"},[_c('a',{attrs:{"href":_vm.sampleFileUrl}},[_vm._v("Namuna uchun shu excel faylni yuklab oling!")])]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VFileInput,{attrs:{"counter":"","truncate-length":"50","label":"EXCEL FAYLNI KIRITING","id":"import-file-input","outlined":"","clearable":""},model:{value:(_vm.fileInput),callback:function ($$v) {_vm.fileInput=$$v},expression:"fileInput"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"USTOZ FAMILIYASI VA ISMI","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.importFile()}},model:{value:(_vm.teacher_full_name),callback:function ($$v) {_vm.teacher_full_name=$$v},expression:"teacher_full_name"}})],1)],1),_c(VBtn,{attrs:{"color":"info"},on:{"click":function($event){return _vm.importFile()}}},[_vm._v("Import qilish")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }